
import { computed, defineComponent, ref, reactive } from "vue";

export default defineComponent({
  props: {
    visible: Boolean,
    url: String,
    imageRotate: Number,
  },
  emits: ["update:visible"],
  setup(props, ctx) {
    const imgVisible = computed(() => props.visible);
    const imageRef = ref<any>(null);
    const imgUrl = computed(() => props.url);
    const scale = ref(1);
    // pc端图片移动
    let dragData = reactive({
      dragging: false,
      startX: 0,
      startY: 0,
      imgPosX: 0,
      imgPosY: 0,
    });
    const imageStyle = computed(() => ({
      transform: `translate(${dragData.imgPosX}px, ${
        dragData.imgPosY
      }px) scale(${scale.value}) rotate(${props?.imageRotate || 0}deg)`,
      transition: ".1s",
    }));
    const handleImgClick = () => {
      console.log("clickclick");
      dragData.imgPosX = 0;
      dragData.imgPosY = 0;
      scale.value = 0.5;
      ctx.emit("update:visible", false);
    };
    const zoomIn = () => {
      scale.value += 0.1;
    };

    const zoomOut = () => {
      if (scale.value > 0.2) {
        scale.value -= 0.1;
      }
    };
    const handleWheel = (event: any) => {
      if (event.deltaY < 0) {
        zoomIn();
      } else {
        zoomOut();
      }
    };
    const handleMouseDown = (event: any) => {
      console.log("downdown");
      event.stopPropagation();
      dragData.dragging = true;
      dragData.startX = event.clientX - dragData.imgPosX;
      dragData.startY = event.clientY - dragData.imgPosY;
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      imageRef.value.style.pointerEvents = "none";
    };
    const handleMouseUp = (event: any) => {
      console.log("upupup");
      event.stopPropagation();
      if (dragData.dragging) {
        dragData.dragging = false;
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
      imageRef.value.style.pointerEvents = "auto";
    };
    const handleMouseMove = (event: any) => {
      console.log("movemovemove");
      if (dragData.dragging) {
        const targetPosX = event.clientX - dragData.startX;
        const targetPosY = event.clientY - dragData.startY;
        if (
          Math.abs(targetPosX - dragData.imgPosX) > 1 ||
          Math.abs(targetPosY - dragData.imgPosY) > 1
        ) {
          dragData.imgPosX += (targetPosX - dragData.imgPosX) / 10;
          dragData.imgPosY += (targetPosY - dragData.imgPosY) / 10;
        }
      }
    };
    // 触摸控制图片移动
    // 触摸开始
    const touchStart = (event: any) => {
      event.preventDefault(); // 防止默认滚动行为
      dragData.dragging = true;
      const touch = event.touches[0];
      dragData.startX = touch.clientX - dragData.imgPosX;
      dragData.startY = touch.clientY - dragData.imgPosY;
    };

    // 触摸移动
    const touchMove = (event: any) => {
      event.preventDefault(); // 防止默认滚动行为
      if (dragData.dragging) {
        const touch = event.touches[0];
        const targetPosX = touch.clientX - dragData.startX;
        const targetPosY = touch.clientY - dragData.startY;
        if (
          Math.abs(targetPosX - dragData.imgPosX) > 1 ||
          Math.abs(targetPosY - dragData.imgPosY) > 1
        ) {
          dragData.imgPosX += (targetPosX - dragData.imgPosX) / 10;
          dragData.imgPosY += (targetPosY - dragData.imgPosY) / 10;
        }
      }
    };

    // 触摸结束
    const touchEnd = () => {
      dragData.dragging = false;
    };
    return {
      imgUrl,
      imageRef,
      imgVisible,
      imageStyle,
      zoomOut,
      zoomIn,
      touchEnd,
      touchMove,
      touchStart,
      handleWheel,
      handleMouseUp,
      handleMouseMove,
      handleImgClick,
      handleMouseDown,
    };
  },
});


import _ from "lodash";
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";

import emitter, { EmitterEvents } from "@/utils/eventEmitter";
import { UploadTaskStatus } from "../dataMap";
import { appendFastClassWorkRecord } from "@/services/homework";
import { createRandomCode } from "../utils";
import { ElMessage } from "element-plus";

interface UploadTask {
  id: string;
  name: string;
  status: string;
  isUpload: boolean; // 判断是否上传至接口成功
  progress?: number;
  message?: string;
  file: File;
  studentWorkId: number;
  fastClassWorkId: number;
  studentId?: number;
  score: number | undefined;
  star?: number;
  result?: {
    url: string;
    md5: string;
    size: number;
    name: string;
  };
}

export default defineComponent({
  props: {
    batchUploadProps: Object,
    fastClassWorkId: Number,
    batchFiles: Array,
    addWorkerTask: Function,
    stopWorker: Function,
    closeBatchUploadModal: Function,
    restUploadingObj: Function,
    isUploading: Boolean,
    updateStudentRecords: Function,
  },
  emits: ["update:batchFiles", "update:isUploading", "update:sch"],
  setup(props, ctx) {
    const type = computed(() => props.batchUploadProps?.type);
    let taskLen = 0; // 进入上传任务池的任务数量
    let taskFinishLen = 0; // 已有上传结果的任务数量
    const uploadBtn = ref<any>(null);
    const isShowTooltip = ref(false);
    const activeTab = ref<string>("working");
    const activeTabFiles = ref<UploadTask[]>([]);
    // 进行中任务
    const uploadingFiles = computed<UploadTask[]>(() => {
      const files = activeTabFiles.value.filter(
        (file: UploadTask) =>
          file.status !== UploadTaskStatus.DONE || !file.isUpload
      );
      // 进行中任务排序，以正在进行、等待进行、最后失败任务排序
      const pendingFiles = files.filter(
        (file: UploadTask) => file.status === UploadTaskStatus.PENDING
      );
      const doingFiles = files
        .filter(
          (file: UploadTask) =>
            file.status === UploadTaskStatus.DOING ||
            file.status === UploadTaskStatus.START ||
            (!file.isUpload && file.status === UploadTaskStatus.DONE)
        )
        .reverse();
      const failedFiles = files.filter(
        (file: UploadTask) => file.status === UploadTaskStatus.FAILED
      );
      return [...doingFiles, ...pendingFiles, ...failedFiles] as UploadTask[];
    });

    // 已完成任务
    const finishedFiles = computed<UploadTask[]>(() => {
      return activeTabFiles.value.filter(
        (file: UploadTask) =>
          file.status === UploadTaskStatus.DONE && file.isUpload
      );
    });

    const uploadingTabTitle = computed(() => {
      const uploadingFilesNum =
        uploadingFiles.value.filter(
          (file) => file.status !== UploadTaskStatus.FAILED
        ).length || 0;
      return "进行中  " + uploadingFilesNum;
    });

    const closeModal = () => {
      if (props && props.closeBatchUploadModal) {
        props.closeBatchUploadModal();
      }
    };

    // 检查文件命名
    const checkFiles = (currentFiles: UploadTask[]) => {
      let tempFiles: UploadTask[] = [];
      tempFiles = currentFiles.map((file: UploadTask) => {
        const lastPointIndex = file.name.lastIndexOf(".");
        const fileName = _.cloneDeep(file.name).slice(0, lastPointIndex);
        const studentName = fileName.split("_")[0];

        const { classStudents } = props.batchUploadProps || {};
        let student: any;
        let isStandards = true;
        if (classStudents) {
          student = classStudents.find(
            (classStu: any) => classStu.name === studentName
          );
          isStandards = classStudents.find((classStu: any) =>
            studentName.includes(classStu.name)
          );
        }

        if (!student && !isStandards) {
          return {
            ...file,
            status: UploadTaskStatus.FAILED,
            message: "上传失败,学生不在此班级",
          };
        } else if (!student && isStandards) {
          return {
            ...file,
            status: UploadTaskStatus.FAILED,
            message: "上传失败,命名不规范",
          };
        }
        return {
          ...file,
          studentWorkId: student.studentWorkId,
          studentId: student.id,
        };
      });
      return tempFiles.filter(Boolean);
    };

    const handleChangeFileList = (event: Event) => {
      const { files } = (event.target as HTMLInputElement) || {};
      const currentFiles: UploadTask[] = [];
      if (files) {
        const _files = [...files];
        _files.forEach((_file) => {
          currentFiles.push({
            id: createRandomCode(),
            name: _file.name,
            status: UploadTaskStatus.PENDING,
            isUpload: false,
            message: "准备上传",
            progress: 0,
            file: _file,
            studentWorkId: -1,
            fastClassWorkId: props.fastClassWorkId || -1,
            score: undefined,
          });
        });
      }
      (uploadBtn.value as any).value = "";

      const checkedFiles = checkFiles(currentFiles);
      activeTabFiles.value.push(...checkedFiles);
      const currentUploadFiles = checkedFiles
        .map((_file) => {
          if (_file.status !== UploadTaskStatus.FAILED) {
            return {
              ..._file,
            };
          }
        })
        .filter(Boolean);
      if (currentUploadFiles.length > 0) {
        props.addWorkerTask && props.addWorkerTask(currentUploadFiles);
      }
    };

    const triggerFileInput = () => {
      if (uploadBtn.value) {
        (uploadBtn.value as HTMLElement).click();
      }
    };

    // 关于tooltip功能,即鼠标放在任务名上显示全名
    const onMouseOver = (e: Event) => {
      e.stopImmediatePropagation();
      const { children } = (e.target as any) || {};
      if (children && children?.[0]) {
        let parentWidth = (e.target as any).offsetWidth;
        let contentWidth = children[0].offsetWidth;
        // 判断是否禁用tooltip功能
        isShowTooltip.value = contentWidth <= parentWidth;
      }
    };
    // 删除失败任务
    const handleDeleteTasks = (currentId: string) => {
      const tempFiles: UploadTask[] = [];
      tempFiles.push(
        ...activeTabFiles.value.filter((file) => file.id !== currentId)
      );
      activeTabFiles.value.length = 0;
      activeTabFiles.value.push(...tempFiles);
    };

    const handleRetryTask = (item: any) => {
      const { result, ..._item } = item || {};
      const retryFiles = [
        {
          ..._item,
          status: UploadTaskStatus.PENDING,
          isUpload: false,
          message: "准备上传",
          progress: 0,
        },
      ];
      props.addWorkerTask && props.addWorkerTask(retryFiles);
    };

    const isAppending = ref(false);
    const waitAppendUploadJobs = ref<any>([]); // append上传队列（上传完成一个后再进行下一个）

    // 上传学生作品
    const appendStudentRecord = () => {
      return new Promise((resolve, reject) => {
        console.log("waitAppendUploadJobs.value:", waitAppendUploadJobs.value);
        if (waitAppendUploadJobs.value.length === 0) {
          console.log("append student record over");
          isAppending.value = false;
          resolve("");
          return;
        }
        const job = waitAppendUploadJobs.value.shift();
        const { extra, id } = job;
        const { result, score, studentId } = extra || {};
        if (!studentId) {
          resolve("");
          return;
        }
        const params = {
          fast_classwork_id: props.fastClassWorkId,
          student_id: studentId,
          result_item: JSON.stringify(result),
          teacher_assess_result_level: score,
        };
        appendFastClassWorkRecord(params)
          .then(() => {
            activeTabFiles.value.map((file: UploadTask) => {
              if (file.id === id) {
                file.progress = 100;
                file.isUpload = true;
                file.status = UploadTaskStatus.DONE;
                file.result = extra;
                file.message = "";
              }
              return file;
            });
            props.updateStudentRecords &&
              props.updateStudentRecords(studentId, {
                result: [result],
              });
            resolve("");
          })
          .catch((e) => {
            console.error("学生作品添加失败", e);
            activeTabFiles.value.map((file: UploadTask) => {
              if (file.id === id) {
                file.progress = 0;
                file.status = UploadTaskStatus.FAILED;
                file.result = extra;
                file.message = "上传失败，请重试";
              }
              return file;
            });
            reject(e);
          })
          .finally(() => {
            taskFinishLen++;
            if (taskLen === taskFinishLen) {
              props.stopWorker && props.stopWorker();
            }
            appendStudentRecord();
          });
      });
    };

    // 上传任务相关
    const addTask = (job: any) => {
      console.log("on add Task:", job);
      taskLen += job?.length || 0;
    };

    const onTaskProgress = (job: any) => {
      const { id, progress, status, extra } = job || {};
      activeTabFiles.value.map((file: UploadTask) => {
        if (file.id === id) {
          file.progress = progress;
          file.status = status;
          file.result = extra;
        }
        return file;
      });
      // 每上传完成一个作品，立即在评阅卡片中显示
      if (progress === 100 && extra?.result?.params?.url) {
        if (!isAppending.value) {
          isAppending.value = true;
          waitAppendUploadJobs.value.push(job);
          appendStudentRecord();
        } else {
          waitAppendUploadJobs.value.push(job);
        }
        // appendStudentRecord(job).then(() => {
        //   taskFinishLen++;
        //   if (taskLen === taskFinishLen) {
        //     props.stopWorker && props.stopWorker();
        //   }
        // });
      }
      // 判断当前所有上传任务是否都已经完成
      if (status === UploadTaskStatus.FAILED) {
        taskFinishLen++;
        if (taskLen === taskFinishLen) {
          props.stopWorker && props.stopWorker();
        }
      }
    };

    const onTaskStop = () => {
      console.log("task stop");
      ctx.emit("update:isUploading", false);
      if (type.value === "pdf") {
        ElMessage.success({
          type: "success",
          message: "pdf上传成功",
          offset: 200,
        });
      }
    };

    watch(
      () => props.batchFiles,
      () => {
        activeTabFiles.value = [...(props.batchFiles as UploadTask[])];
      },
      { deep: true, immediate: true }
    );

    onMounted(() => {
      emitter.on(EmitterEvents.ADD_WORK_TASK, addTask);
      emitter.on(EmitterEvents.ON_WORK_TASK_PROGRESS, onTaskProgress);
      emitter.on(EmitterEvents.ON_TASK_STOP, onTaskStop);
    });
    onBeforeUnmount(() => {
      ctx.emit("update:batchFiles", activeTabFiles.value);
      emitter.off(EmitterEvents.ADD_WORK_TASK, addTask);
      emitter.off(EmitterEvents.ON_WORK_TASK_PROGRESS, onTaskProgress);
      emitter.off(EmitterEvents.ON_TASK_STOP, onTaskStop);
    });

    return {
      type,
      uploadBtn,
      activeTab,
      activeTabFiles,
      uploadingFiles,
      UploadTaskStatus,
      isShowTooltip,
      finishedFiles,
      uploadingTabTitle,
      closeModal,
      handleChangeFileList,
      triggerFileInput,
      onMouseOver,
      handleDeleteTasks,
      handleRetryTask,
    };
  },
});


import { computed, defineComponent, nextTick, ref, watch } from "vue";
import type { PropType } from "vue";
import { StudentRecords } from "../type";

export default defineComponent({
  props: {
    type: {
      type: String as PropType<"review" | "operate">,
      default: "review",
    },
    records: Array as PropType<StudentRecords[]>,
    selectedStudents: Array as PropType<StudentRecords[]>,
    uploadStudentRecords: Function,
    openReviewDetail: Function,
    isAllSelected: Boolean,
    isShowFunctionBar: Boolean,
  },
  setup(props, { emit }) {
    const studentRecords = computed(() => {
      return props.records;
    });
    const showFunctionBar = computed(() => props.isShowFunctionBar);
    const currentSelectedStudents = ref<StudentRecords[]>([]);
    watch(
      () => props.selectedStudents,
      () => {
        if (props.selectedStudents) {
          currentSelectedStudents.value.length = 0;
          currentSelectedStudents.value.push(...props.selectedStudents);
        }
      },
      { deep: true }
    );
    watch(
      () => props.isAllSelected,
      () => {
        studentRecords.value?.forEach((e: StudentRecords) => {
          if (e.result && e.result.length > 0) {
            e.isSelected = props.isAllSelected;
          }
        });
      },
      { deep: true }
    );
    const handleClickCard = (records: StudentRecords) => {
      if (records?.result && records.result?.length > 0) {
        handleOpenReviewDetail(records);
      } else {
        handleOpenSingleUploadModal(records);
      }
    };
    const handleOpenSingleUploadModal = (records: StudentRecords) => {
      props.uploadStudentRecords && props.uploadStudentRecords(records);
    };

    const handleOpenReviewDetail = (records: StudentRecords) => {
      props.openReviewDetail && props.openReviewDetail(records);
    };
    const clickCheckBox = (item: any) => {
      if (item?.result && item.result?.length > 0) {
        emit("checkoutSelected", item.id);
      }
    };

    // 判断文字显示的是综合得分还是别的
    const renderText = (item: any) => {
      if (item.status === 1) {
        return "评阅中";
      }
      if (item.status === 2) {
        return `综合得分：${item.score}`;
      }
      return "";
    };
    const turnLeftOrRight = (deg: number) => {
      studentRecords.value?.forEach((e: StudentRecords) => {
        if (e.isSelected === true && e.result && e.result?.length > 0) {
          e.rotate += deg;

          const rotateDiv = document.querySelector(
            `img[flag='${e.id}']`
          ) as HTMLElement;
          if (Math.abs((e.rotate / 90) % 2) === 1) {
            let curWidth = rotateDiv.offsetWidth;
            let curHeight = rotateDiv.offsetHeight;
            let val = curWidth / curHeight;
            rotateDiv.style.width = `${curHeight}px`;
            rotateDiv.style.height = `${curHeight / val}px`;
          } else {
            rotateDiv.style.width = "100%";
            rotateDiv.style.height = "100%";
          }
          rotateDiv.style.transform = `rotate(${e.rotate}deg)`;
        }
      });
    };
    return {
      studentRecords,
      handleOpenReviewDetail,
      handleClickCard,
      handleOpenSingleUploadModal,
      clickCheckBox,
      turnLeftOrRight,
      showFunctionBar,
      renderText,
    };
  },
});

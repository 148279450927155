import * as XLSX from "xlsx";

export const acceptXlsx =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel";

/**
 * 将 Excel 文件里的数据导出为数组
 * @param {*} file excel 文件
 * @returns data: { Sheet1: [array], Sheet2: [array], ...}
 */
const xlsxImport = (file: File) => {
  return new Promise((resolve, reject) => {
    if (!acceptXlsx.includes(file.type)) {
      reject("xlsx文件格式错误！");
    }
    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      try {
        const { result } = event.target || {};
        // 以二进制流方式读取得到整份excel表格对象
        const { Sheets, SheetNames } = XLSX.read(result, { type: "binary" });
        // 存储获取到的数据
        const data: any = {};
        // 遍历每张工作表进行读取
        SheetNames.forEach((key: any) => {
          const sheet = Sheets[key];
          data[key] = XLSX.utils.sheet_to_json(sheet);
        });
        resolve(data);
      } catch (e) {
        console.error(e);
        reject("xlxs文件解析错误！");
      }
    };
    fileReader.readAsBinaryString(file);
  });
};

const xlsxExport = (sheetData: any, fileName = "example.xlsx") => {
  const ws = XLSX.utils.aoa_to_sheet(sheetData);
  const workSheetName = "Sheet1";
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, ws, workSheetName);
  return XLSX.writeFile(workbook, fileName, { type: "binary" });
};

export { xlsxExport, xlsxImport };

import Worker from "workerize-loader?inline&ready&name=worker/fast-class-work.[contenthash:8]!../worker";
import { useStore } from "vuex";
import emitter, { EmitterEvents } from "@/utils/eventEmitter";

export default () => {
  let worker: any = null;
  const store = useStore();

  const initWorker = () => {
    return new Promise((resolve, reject) => {
      if (worker) {
        return;
      }
      worker = new Worker();
      worker.ready
        .then(() => worker.createTaskController())
        .then(() => (worker.onmessage = onWorkerMessage))
        .then(() => {
          worker.initOssResource(store.state.token);
        })
        .then(resolve(worker))
        .catch(reject);
      resolve(worker);
      return;
    });
  };

  const destroyWorker = () => {
    return new Promise((resolve, reject) => {
      if (worker) {
        worker
          .destroyTaskController()
          .then(() => worker.terminate())
          .then(() => (worker = null))
          .catch((e: any) => reject(e));
      }
    });
  };

  /**
   * 添加任务
   * @param files
   */
  const addWorkerTask = (task: any[] | null) => {
    return new Promise((resolve, reject) => {
      worker &&
        worker
          .addTask(task)
          .then((result: any) => {
            task = null;
            console.log(result);
            emitter.emit(EmitterEvents.ADD_WORK_TASK, result);

            resolve(result);
          })
          .catch(reject);
    });
  };

  const onWorkerMessage = (e: any) => {
    const { type, result = {} } = e.data;
    if (type === "progress") {
      emitter.emit(EmitterEvents.ON_WORK_TASK_PROGRESS, result);
    } else if (type === "stop") {
      emitter.emit(EmitterEvents.ON_TASK_STOP);
    }
  };

  const stopWorker = () => {
    worker && worker.stopTask();
  };

  // onMounted(() => {
  //   initWorker();
  // });
  // onBeforeUnmount(() => {
  //   destroyWorker();
  // });

  return {
    initWorker,
    stopWorker,
    destroyWorker,
    addWorkerTask,
    onWorkerMessage,
  };
};

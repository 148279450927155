
interface Resource {
  studentId: number | string;
  score: number;
  image1: string;
  image1Blob: Blob;
  image2: string;
  image2Blob: Blob;
  isConfirm: boolean;
  isSelectedImgIndex: number;
}
const WarningMsg: any = {
  work: "请选择需要上传的学生作品",
  student: "请选择该作品关联的学生",
};
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { PropType } from "vue";
import Confirm from "./confirm.vue";
import { StudentRecords } from "../type";
import { ElMessage } from "element-plus";
import _ from "lodash";
import { createRandomCode } from "../utils";
import ShowImage from "@/components/showImage.vue";

export default defineComponent({
  props: {
    visible: Boolean,
    pdfResult: [],
    studentRecords: [] as PropType<StudentRecords[]>,
    unKnowPdfResult: [],
  },
  components: {
    Confirm,
    ShowImage,
  },
  emits: ["update:visible", "update:pdfResult"],
  setup(props, ctx) {
    const modalVisible = computed(() => props.visible);
    // el-rate星星的样式
    const iconClasses = ref([
      "el-icon-selectedStar",
      "el-icon-selectedStar",
      "el-icon-selectedStar",
    ]);
    const disable = ref(false); // 是否能匹配
    const firstWarm = ref(false);
    const unSelectedStarClass = ref("el-icon-unSelectedStar");
    const selectedStudent = ref<any>("");
    const unMatchPdfResult = ref<any>(props.unKnowPdfResult);
    const unMatchNum = ref(0);
    const totalNum = computed(() => unMatchPdfResult.value.length);
    const currentNum = ref(1);
    const currentResource = ref<Resource | any>({});
    const noMatchedStudents = computed(() => {
      const _students: { id: number; label: string; value: number }[] = [];
      const pdfResStudentIds: number[] = [];
      const pdfParseResStudents: any = [];

      props.pdfResult &&
        (props.pdfResult as any)?.forEach((pdfRes: any) => {
          pdfResStudentIds.push(pdfRes.studentId);
          const isExit = pdfParseResStudents.find(
            (stu: any) => stu.id === pdfRes.studentId
          );
          if (!isExit) {
            pdfParseResStudents.push({
              id: pdfRes.studentId,
              label: pdfRes.name,
              value: pdfRes.studentId,
            });
          }
        });

      props.studentRecords?.forEach((student: StudentRecords) => {
        // !student?.result ||(student.result && student.result.length === 0)) &&
        if (!pdfResStudentIds.includes(student.id)) {
          _students.push({
            id: student.id,
            label: student.name,
            value: student.id,
          });
        }
      });
      return [..._students, ...pdfParseResStudents];
    });
    const closeConfirmVisible = ref(false);
    const showImgDetails = ref(false);
    const showImgUrl = ref("");
    const showWarnMsg = ref("");

    const alphabets = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789".split("");
    const studentsList = ref(props.studentRecords);
    const selectedAlphabets = ref("");
    const selectedStudentName = computed(() => {
      if (currentResource.value?.studentId) {
        const isExitStudent = props.studentRecords?.find(
          (student: StudentRecords) =>
            student.id === currentResource.value?.studentId
        );
        return isExitStudent?.name;
      }
      return "";
    });

    const appendToAlphabets = (alphabet: string) => {
      selectedAlphabets.value += alphabet;
    };
    const backspaceAlphabets = () => {
      selectedAlphabets.value = selectedAlphabets.value.slice(0, -1);
    };
    const clearAlphabets = () => {
      selectedAlphabets.value = "";
    };
    const handleSelectedStudent = (student: StudentRecords) => {
      if (currentResource.value.studentId === student.id) {
        currentResource.value.studentId = "";
      } else {
        currentResource.value.studentId = student.id;
      }
    };

    const handleShowImg = (url: string) => {
      if (!url) {
        return;
      }
      showImgDetails.value = true;
      showImgUrl.value = url;
    };

    const handleAbandonUpload = () => {
      ctx.emit("update:pdfResult", []);
      ctx.emit("update:visible", false);
    };
    const handleCloseModal = () => {
      closeConfirmVisible.value = true;
    };
    // 重置当前显示的作品
    const restCurrentInfos = () => {
      showWarnMsg.value = "";
      Object.assign(currentResource.value, {
        image1: "",
        image2: "",
        image1Blob: "",
        image2Blob: "",
        isSelectedImgIndex: 0,
        studentId: "",
        isConfirm: false,
        score: 0,
      });
    };

    const blobToArrayBuffer = (blob: Blob) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsArrayBuffer(blob);
      });
    };

    const getCurrentInfos = () => {
      restCurrentInfos();
      const { images, qrCode, isConfirm, studentId, isSelectedImgIndex } =
        unMatchPdfResult.value[currentNum.value - 1] || {};
      let selectedStudentId: any = "";
      if (isConfirm) {
        selectedStudentId = studentId;
      } else if (qrCode) {
        const studentId = JSON.parse(qrCode);
        if (studentId) {
          const isExitCurrentStudent = props.studentRecords?.find(
            (student: StudentRecords) => student.id === +studentId
          );
          isExitCurrentStudent && (selectedStudentId = +studentId);
        }
      }

      blobToArrayBuffer(images[0]).then((res) => {
        console.log("currentResourceImage image1 buffer", res);
      });
      blobToArrayBuffer(images[1]).then((res) => {
        console.log("currentResourceImage image2 buffer", res);
      });

      Object.assign(currentResource.value, {
        image1: window.URL.createObjectURL(images[0] as Blob),
        image2: window.URL.createObjectURL(images[1] as Blob),
        image1Blob: images[0],
        image2Blob: images[1],
        isSelectedImgIndex: isSelectedImgIndex
          ? isSelectedImgIndex
          : qrCode
          ? 1
          : 0,
        studentId: selectedStudentId,
        isConfirm: isConfirm || false,
        score: unMatchPdfResult.value[currentNum.value - 1]?.score || undefined,
      });
    };
    const handleNext = _.debounce(() => {
      if (currentNum.value === totalNum.value) {
        ElMessage.warning({
          type: "warning",
          message: "已经是最后一份作业",
          offset: 200,
        });
        return;
      }
      currentNum.value++;
    }, 200);
    const handlePrevious = _.debounce(() => {
      if (currentNum.value === 1) {
        ElMessage.warning({
          type: "warning",
          message: "已经是第一份作业",
          offset: 200,
        });
        return;
      }
      currentNum.value--;
    }, 200);

    const updateUnMatchResults = () => {
      Object.assign(unMatchPdfResult.value[currentNum.value - 1], {
        isConfirm: true,
        studentId: currentResource.value.studentId,
        score: currentResource.value.score,
        isSelectedImgIndex: currentResource.value.isSelectedImgIndex,
        imageBlob:
          currentResource.value.isSelectedImgIndex === 1
            ? currentResource.value.image1Blob
            : currentResource.value.image2Blob,
      });
    };

    const handleUpdateRecords = () => {
      if (!currentResource.value.studentId) {
        showWarnMsg.value = "student";
        return;
      }
      if (!currentResource.value.isSelectedImgIndex) {
        showWarnMsg.value = "work";
        return;
      }
      currentResource.value.isConfirm = true;
      updateUnMatchResults();
    };

    const handleContinue = () => {
      disable.value = false;
      firstWarm.value = false;
    };

    const handleUpload = () => {
      disable.value = true;
      unMatchNum.value =
        unMatchPdfResult.value.filter((res: Resource) => !res?.isConfirm)
          ?.length || 0;
      if (unMatchNum.value > 0 && !firstWarm.value) {
        firstWarm.value = true;
        return;
      }
      const pdfParseResource = props.pdfResult
        ? [...(props.pdfResult as any)]
        : [];
      unMatchPdfResult.value.forEach((res: any) => {
        if (res?.isConfirm) {
          const student = props.studentRecords?.find(
            (student: StudentRecords) => student.id === res.studentId
          );
          if (student) {
            const _res = [
              {
                id: createRandomCode(),
                name: student.name,
                status: "pending",
                progress: 0,
                type: "image",
                file: new File([res.imageBlob], student.name + ".jpg"),
                studentWorkId: student.studentWorkId,
                score: res.score ? res.score : undefined,
                fastClassWorkId: student.fastClassWorkId,
                studentId: student.id,
              },
            ];
            pdfParseResource.push(..._res);
          }
        }
      });

      ctx.emit("update:pdfResult", pdfParseResource);
      ctx.emit("update:visible", false);
    };

    // 确认完成后，图片、学生选择、分数有变动则重新出现确认按钮
    const handleSelectedImg = (e: Event, index: number) => {
      e.stopPropagation();
      if (disable.value || currentResource.value.isSelectedImgIndex === index) {
        return;
      }
      showWarnMsg.value === "work" && (showWarnMsg.value = "");
      currentResource.value.isSelectedImgIndex = index;
      currentResource.value.isConfirm = false;
      unMatchPdfResult.value[currentNum.value - 1].isConfirm = false;
    };

    watch(
      () => currentResource.value.studentId,
      () => {
        if (
          currentResource.value.studentId &&
          currentResource.value.studentId !==
            unMatchPdfResult.value[currentNum.value - 1].studentId
        ) {
          currentResource.value.isConfirm = false;
          unMatchPdfResult.value[currentNum.value - 1].isConfirm = false;
        }
        if (currentResource.value.studentId) {
          showWarnMsg.value === "student" && (showWarnMsg.value = "");
        }
      }
    );
    watch(
      () => currentResource.value.score,
      () => {
        if (
          currentResource.value.score &&
          currentResource.value.score !==
            unMatchPdfResult.value[currentNum.value - 1].score
        ) {
          currentResource.value.isConfirm = false;
          unMatchPdfResult.value[currentNum.value - 1].isConfirm = false;
        }
      }
    );
    watch(
      () => currentNum.value,
      () => {
        getCurrentInfos();
      }
    );
    watch(
      () => selectedAlphabets.value,
      () => {
        const _stuList =
          props.studentRecords &&
          props.studentRecords.filter((student: any) =>
            student?.namePinYin?.includes(
              selectedAlphabets.value?.toLowerCase()
            )
          );
        studentsList.value = undefined;
        studentsList.value = _.cloneDeep(_stuList);
      }
    );
    onMounted(() => {
      getCurrentInfos();
    });

    return {
      disable,
      selectedAlphabets,
      studentsList,
      currentNum,
      totalNum,
      unMatchNum,
      iconClasses,
      modalVisible,
      currentResource,
      selectedStudent,
      noMatchedStudents,
      unSelectedStarClass,
      closeConfirmVisible,
      handleNext,
      handlePrevious,
      showImgDetails,
      showImgUrl,
      showWarnMsg,
      WarningMsg,
      alphabets,
      selectedStudentName,
      handleSelectedStudent,
      appendToAlphabets,
      backspaceAlphabets,
      clearAlphabets,
      handleShowImg,
      handleUpload,
      handleContinue,
      handleCloseModal,
      handleAbandonUpload,
      handleUpdateRecords,
      handleSelectedImg,
    };
  },
});


import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import xgplayer from "@/utils/xgPlayer";
import _ from "lodash";

export default defineComponent({
  name: "VideoElement",
  props: {
    url: { type: String, default: "" },
    poster: String,
    resetVideoState: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    let player: any = undefined;
    const videoContainerRef = ref<HTMLDivElement>();

    const resetVideo = () => {
      player && player.pause();
      player && (player.currentTime = 0);
    };

    const initPlayer = () => {
      if (player) {
        player.destroy();
      }
      if (props?.url) {
        return new Promise<void>((resolve) => {
          player = xgplayer(videoContainerRef.value!, {
            url: props.url,
            poster: props.poster,
          });
          resolve();
        });
      } else {
        return new Promise<void>((resolve) => {
          resolve();
        });
      }
    };

    watch(
      () => props.resetVideoState,
      () => {
        console.log("props.resetVideoState:", props.resetVideoState);
        if (props.resetVideoState) {
          resetVideo();
        }
      }
    );
    watch(
      () => props.url,
      async () => {
        console.log("video url changedddddddd,", props.url);
        await initPlayer();
      }
    );

    onMounted(() => {
      initPlayer();
    });
    onBeforeUnmount(() => {
      if (player) {
        player.destroy();
      }
    });

    return {
      videoContainerRef,
      resetVideo,
    };
  },
});


const AudioState = {
  PAUSE: "pause",
  PLAYING: "playing",
};
import { defineComponent, onMounted, ref, watch } from "vue";
export default defineComponent({
  name: "AudioElement",
  props: {
    url: String,
    resetAudioState: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const audioSrc = ref(props.url);
    const audioVideoRef = ref();
    const audioRef = ref();
    const audioState = ref<string>(AudioState.PAUSE);
    const audioTime = ref({
      current: 0,
      duration: 0,
      progress: 0,
    });

    // 音频播放状态变化
    const handleChangeAudioState = () => {
      if (audioState.value === AudioState.PAUSE) {
        audioState.value = AudioState.PLAYING;
        audioRef.value.play();
        audioVideoRef.value.play();
      } else {
        audioState.value = AudioState.PAUSE;
        audioRef.value.pause();
        audioVideoRef.value.pause();
      }
    };

    // 格式化音频时间
    const getTimeFormat = (time: number) => {
      if (isNaN(time)) {
        return "00:00";
      }
      const _time = +time.toFixed(0);

      if (_time < 60) {
        if ((_time + "").length === 1) {
          return "00:0" + _time;
        } else {
          return "00:" + _time;
        }
      } else {
        const seconds = _time % 60;
        const minutes = (_time / 60).toFixed(0);
        let ss = "";
        if ((seconds + "").length === 1) {
          ss = "0" + (seconds + "");
        } else {
          ss = "" + seconds;
        }
        if (minutes.length === 1) {
          return `0${minutes}:${ss}`;
        } else {
          return `${minutes}:${ss}`;
        }
      }
    };

    // 拖动音频进度条
    const handleChangeAudioProgress = (seek: number) => {
      const _seekTime = +((seek / 100) * audioTime.value.duration).toFixed(0);

      if (audioState.value === AudioState.PAUSE) {
        handleChangeAudioState();
      }
      audioRef.value.currentTime = _seekTime;
    };

    // 重置音频组件
    const resetAudio = () => {
      audioState.value === AudioState.PLAYING && handleChangeAudioState();
      audioTime.value.current = 0;
      audioTime.value.progress = 0;
      audioRef.value && (audioRef.value.currentTime = 0);
    };
    const initAudio = () => {
      audioRef.value.ontimeupdate = (e: any) => {
        const { currentTime, duration } = e?.target;
        audioTime.value.current = currentTime;
        audioTime.value.duration = duration;
        audioTime.value.progress = +((currentTime / duration) * 100).toFixed(0);
      };
      audioRef.value.onended = () => {
        audioTime.value.current = 0;
        audioTime.value.progress = 0;
        handleChangeAudioState();
      };
      audioVideoRef.value.onended = () => {
        if (audioState.value === "playing") {
          audioVideoRef.value.currentTime = 0;
          audioVideoRef.value.play();
        }
      };
      audioRef.value.oncanplay = (e: any) => {
        const { duration } = e?.target;
        audioTime.value.duration = duration;
      };

      watch(
        () => props.resetAudioState,
        () => {
          if (props.resetAudioState) {
            resetAudio();
          }
        }
      );
    };
    onMounted(() => {
      initAudio();
    });
    return {
      audioSrc,
      audioRef,
      audioState,
      audioTime,
      audioVideoRef,
      resetAudio,
      getTimeFormat,
      handleChangeAudioState,
      handleChangeAudioProgress,
    };
  },
});


interface Assess {
  id: number;
  name: string;
  comment: string;
  score: number;
}
const emptyAssess = {
  id: 0,
  name: "",
  comment: "",
  score: 0,
};
interface Result {
  type: string;
  aiScore?: number;
  params: {
    url: string;
    name: string;
    rotate: number;
    poster?: string;
    record_file_id?: number;
  };
}

interface TabResult {
  id: number;
  name: string;
  aiScore: number;
  url: string;
}

import {
  deleteAssess,
  getFastClassWorkRecord,
  markFastClassWorkRecord,
  updateFastClassWork,
} from "@/services/homework";
import { ElMessage } from "element-plus";
import _ from "lodash";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { AssessRecordType, ReviewTypes, UploadType } from "../dataMap";
import { StudentRecords } from "../type";
import AudioVue from "./audio.vue";
import VideoVue from "./video.vue";
import Confirm from "./confirm.vue";
import ShowImage from "@/components/showImage.vue";
import { FastClassWorkEvent, reportStat } from "../statReport";

export default defineComponent({
  props: {
    record: Object as PropType<StudentRecords>,
    noSubmitRecord: Object as PropType<StudentRecords>,
    _uploadNoAllowed: Boolean, // 是否允许教师端上传学生作品
    _assessNoAllowed: Boolean, // 是否允许教师端打分
    closeDetail: Function,
    recordsReload: Function,
    nextStudentRecord: Function,
    previousStudentRecord: Function,
    uploadStudentRecords: Function,
  },
  name: "FastClassWorkReviewDetail",
  components: { AudioVue, VideoVue, Confirm, ShowImage },
  setup(props) {
    const currentStudent = ref({
      seatNo: "",
      name: "",
      submitTime: "",
    });
    const router = useRouter();
    const currentResults = ref<Result[]>([]);
    const carouselRef = ref();

    const uploadNoAllowed = computed(() => props._uploadNoAllowed);
    const assessNoAllowed = computed(() => props._assessNoAllowed);

    const tabsResult = ref<TabResult[]>([]);

    const assessLoading = ref(false);
    const teacherAssess = ref<Assess>({ ...emptyAssess });
    const studentAssess = ref<Assess[]>([]);
    const selfAssess = ref<Assess>({ ...emptyAssess });

    const activeIndex = ref(0);

    const isSelectedAssessId = ref(0);

    const star = ref(0);
    const teacherComment = ref("");

    const deleteWorkConfirmVisible = ref(false);
    const deleteAssessConfirmVisible = ref(false);

    const showImgVisible = ref(false);
    const showImgUrl = ref("");
    const showImgRotate = ref(0);

    // el-rate星星的样式
    const starIconClasses = ref([
      "el-icon-selectedStar",
      "el-icon-selectedStar",
      "el-icon-selectedStar",
    ]);
    const unSelectedStarClass = ref("el-icon-unSelectedStar");

    const handleChangeCarousel = (index: number) => {
      activeIndex.value = index;
    };

    const handleShowImg = (item: any) => {
      const { url, rotate } = item?.params || {};
      showImgVisible.value = true;
      showImgUrl.value = url;
      showImgRotate.value = rotate || 0;
    };

    const handleOpenDeleteWorkConfirm = () => {
      deleteWorkConfirmVisible.value = true;
    };

    const handleOpenDeleteAssessConfirm = (id: number) => {
      deleteAssessConfirmVisible.value = true;
      isSelectedAssessId.value = id;
    };

    const handleOpenUpload = () => {
      if (props.record && props.uploadStudentRecords) {
        props.uploadStudentRecords(props.record, UploadType.MODAL_SINGLE);
      }
    };

    const handleDeleteAssess = () => {
      return new Promise((resolve, reject) => {
        assessLoading.value = true;
        deleteAssess({ id: isSelectedAssessId.value })
          .then(() => {
            ElMessage.success({
              type: "success",
              message: "删除评论成功",
              offset: 200,
            });
            initRecordDetail();
            resolve("");
          })
          .catch((e) => {
            console.error("删除评论失败", e);
            ElMessage.error({
              type: "error",
              message: "删除评论失败",
              offset: 200,
            });
            reject(e);
          });
      });
    };

    const handleDeleteWork = () => {
      return new Promise((resolve, reject) => {
        let _currentResult = _.cloneDeep(currentResults.value);
        _currentResult = _currentResult
          .map((res: any, index: number) => {
            if (index !== activeIndex.value) {
              const { poster, ..._param } = res.params;
              return { ...res, params: _param };
            }
          })
          .filter(Boolean);

        const updateResult = _.cloneDeep(_currentResult);
        if (props.noSubmitRecord?.result) {
          updateResult.push(...props.noSubmitRecord.result);
        }

        updateFastClassWork({
          id: props.record?.studentWorkId,
          result: JSON.stringify(updateResult),
        })
          .then(() => {
            currentResults.value.length = 0;
            currentResults.value.push(..._currentResult);
            ElMessage.success({
              type: "success",
              message: "删除学生作品成功",
              offset: 200,
            });

            // 刷新评阅列表
            props.recordsReload && props.recordsReload();
            resolve("");
          })
          .catch((e) => {
            console.error(e);
            ElMessage.error({
              type: "error",
              message: "删除学生作品失败",
              offset: 200,
            });
            reject(e);
          });
      });
    };

    // 评价相关
    const setAssessRecords = (
      type: number,
      id: number,
      name: string,
      _score: string,
      content: string
    ) => {
      switch (type) {
        case AssessRecordType.TEACHER: {
          Object.assign(teacherAssess.value, {
            name,
            id,
            score: +_score,
            comment: content,
          });
          star.value = +_score;
          break;
        }
        case AssessRecordType.STUDENT_EACH: {
          studentAssess.value.push({
            name,
            id,
            score: +_score,
            comment: content,
          });
          break;
        }
        case AssessRecordType.STUDENT_SELF: {
          Object.assign(selfAssess.value, {
            name,
            id,
            score: +_score,
            comment: content,
          });
          break;
        }
        default:
          break;
      }
    };

    const setStudentResult = () => {
      currentResults.value.length = 0;
      tabsResult.value.length = 0;
      setTimeout(() => {
        currentStudent.value.name = props.record?.name || "";
        currentStudent.value.seatNo = props.record?.seatNo || "";
        if (!props?.record || !props?.record?.result) {
          currentResults.value = [];
          return;
        }

        props.record.result.forEach((res: any) => {
          if (res.type === ReviewTypes.STUDENT_RECORD_FILE) {
            const isExitTab = tabsResult.value.find(
              (tab: TabResult) =>
                tab.url === res.params.url &&
                tab.id === res.params?.record_file_id
            );
            currentResults.value.push({
              ...res,
              aiScore: isExitTab?.aiScore || 0,
            });
          } else {
            currentResults.value.push(res);
          }
        });
      }, 200);
    };

    const next = _.debounce(() => {
      if (props.nextStudentRecord) {
        const result = props.nextStudentRecord();
        if (!result) {
          ElMessage.warning({
            type: "warning",
            message: "已经是最后一位学生了",
            offset: 200,
          });
        }
      }
    }, 300);

    const previous = _.debounce(() => {
      if (props.previousStudentRecord) {
        const result = props.previousStudentRecord();
        if (!result) {
          ElMessage.warning({
            type: "warning",
            message: "已经是第一位学生了",
            offset: 200,
          });
        }
      }
    }, 300);

    const handleRotateImage = _.debounce((deg: number) => {
      if (!props.record?.result || props.record?.result?.length === 0) {
        return;
      }
      const _currentResult = _.cloneDeep(currentResults.value);
      if (_currentResult?.[activeIndex.value]) {
        const beforeRotate =
          _currentResult?.[activeIndex.value]?.params?.rotate || 0;
        const rotate =
          beforeRotate + deg < 0
            ? 360 + (beforeRotate + deg)
            : (beforeRotate + deg) % 360;
        _currentResult[activeIndex.value].params["rotate"] = rotate;

        currentResults.value[activeIndex.value].params["rotate"] = rotate;
      }
      if (props.noSubmitRecord?.result) {
        _currentResult.push(...props.noSubmitRecord.result);
      }

      updateFastClassWork({
        id: props.record?.studentWorkId,
        result: JSON.stringify(_currentResult),
      }).catch((e) => {
        console.error(e);
      });
    }, 100);

    const closeReviewDetail = () => {
      props.closeDetail && props.closeDetail();
    };

    // 发送评论
    const sendComment = async () => {
      teacherAssess.value.comment = teacherComment.value;
      teacherComment.value = "";
      await handleMarkFastClassWorkRecord();
    };

    const handleMarkFastClassWorkRecord = () => {
      return new Promise((resolve, reject) => {
        markFastClassWorkRecord({
          fast_classwork_record_id: props.record?.studentWorkId,
          comment_content: teacherAssess.value.comment,
          result_level: teacherAssess.value.score
            ? teacherAssess.value.score
            : undefined,
        })
          .then(() => {
            !teacherAssess.value.name && initRecordDetail();

            // 教师评阅数据埋点
            reportStat(FastClassWorkEvent.FASTCLASSWORK_RECORD_MARK, {
              fastClassWork_id: props.record?.fastClassWorkId,
              class_id: router.currentRoute.value?.query.classId,
              student_work_id: props.record?.studentWorkId,
              subject: router.currentRoute.value?.query.subject,
              score: teacherAssess.value.score
                ? teacherAssess.value.score
                : undefined,
              content: teacherAssess.value.comment,
            });
            resolve("");
          })
          .catch((e) => {
            reject(e);
            ElMessage.error({
              type: "error",
              message: "评论失败，请重试",
              offset: 200,
            });
          });
      });
    };

    const handleChangeTab = (tab: TabResult) => {
      currentResults.value?.forEach((result: Result, index: number) => {
        if (
          tab.url === result.params.url &&
          tab.id === result.params?.record_file_id
        ) {
          activeIndex.value = index;
          if (carouselRef.value) {
            carouselRef.value.setActiveItem(activeIndex.value);
          }
        }
      });
    };

    const reset = () => {
      Object.assign(teacherAssess.value, emptyAssess);
      Object.assign(selfAssess.value, emptyAssess);
      studentAssess.value.length = 0;
      teacherComment.value = "";
      star.value = 0;
      currentStudent.value.submitTime = "";
      currentStudent.value.name = "";
      currentStudent.value.seatNo = "";
      currentResults.value.length = 0;
    };
    // 获取作品记录详情
    const initRecordDetail = () => {
      assessLoading.value = true;
      reset();
      setStudentResult();
      return new Promise((resolve, reject) => {
        if (!props.record) {
          resolve("");
          assessLoading.value = false;
          return;
        }
        getFastClassWorkRecord({ id: props.record.studentWorkId })
          .then((res: any) => {
            const { result, assess_records, ..._data } = res.data || {};
            currentStudent.value.submitTime = _data.submit_at;

            assess_records &&
              assess_records.forEach((assessRecord: any) => {
                const {
                  id,
                  comment_content,
                  assess_user_nickname,
                  result_level,
                  assess_source_type,
                } = assessRecord || {};
                setAssessRecords(
                  assess_source_type,
                  id,
                  assess_user_nickname,
                  result_level,
                  comment_content
                );
              });

            result &&
              result.forEach((res: any) => {
                const { type, params, student_record_file } = res;
                if (
                  type === ReviewTypes.STUDENT_RECORD_FILE &&
                  params?.status === 1
                ) {
                  tabsResult.value.push({
                    id: params?.record_file_id,
                    name: params?.name,
                    aiScore: student_record_file?.score,
                    url: params?.url,
                  });
                }
              });
            resolve("");
          })
          .catch((e) => {
            reject(`获取作品详情失败${e}`);
          })
          .finally(() => {
            assessLoading.value = false;
          });
      });
    };

    watch(
      () => props.record?.studentWorkId,
      () => {
        if (props.record?.studentWorkId !== undefined) {
          reset();
          initRecordDetail();
        }
      },
      { immediate: true, deep: true }
    );
    watch(
      () => star.value,
      _.debounce(async () => {
        if (!star.value) {
          return;
        }
        if (star.value !== teacherAssess.value.score) {
          teacherAssess.value.score = star.value;
          await handleMarkFastClassWorkRecord();
        }
      }, 100)
    );
    return {
      star,
      carouselRef,
      handleChangeTab,
      uploadNoAllowed,
      assessNoAllowed,
      tabsResult,
      activeIndex,
      ReviewTypes,
      currentResults,
      teacherComment,
      starIconClasses,
      unSelectedStarClass,
      teacherAssess,
      studentAssess,
      selfAssess,
      currentStudent,
      next,
      previous,
      deleteWorkConfirmVisible,
      deleteAssessConfirmVisible,
      showImgVisible,
      showImgUrl,
      showImgRotate,
      initRecordDetail,
      sendComment,
      handleShowImg,
      handleOpenUpload,
      handleDeleteWork,
      handleDeleteAssess,
      closeReviewDetail,
      handleRotateImage,
      handleChangeCarousel,
      handleOpenDeleteWorkConfirm,
      handleOpenDeleteAssessConfirm,
    };
  },
});

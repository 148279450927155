// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/fastClassWork/audio-pause.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/fastClassWork/audio-playing.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".record-audio[data-v-e53ee4e2],.record-audio video[data-v-e53ee4e2]{width:100%;height:100%}.record-audio .record-audio-controller[data-v-e53ee4e2]{position:relative;bottom:124px;width:100%;height:88px;background:transparent;display:flex;justify-content:space-between;padding-left:20px;padding-right:20px;color:#fff;font-size:23px}.record-audio .record-audio-controller-bar[data-v-e53ee4e2]{display:flex;justify-content:center;align-items:center;z-index:1}.record-audio .record-audio-controller-background[data-v-e53ee4e2]{position:absolute;left:0;top:0;width:100%;height:100%;background-image:linear-gradient(#f7f7f7 20%,#060203);background-blend-mode:normal,normal;opacity:.57}.record-audio .play[data-v-e53ee4e2]{width:80px;height:100%}.record-audio .playing[data-v-e53ee4e2]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.record-audio .pause[data-v-e53ee4e2],.record-audio .playing[data-v-e53ee4e2]{background-size:100% 100%;width:30px;height:30px;cursor:pointer}.record-audio .pause[data-v-e53ee4e2]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.record-audio .progress[data-v-e53ee4e2]{width:calc(100% - 250px);height:100%}.record-audio .time[data-v-e53ee4e2]{width:150px;height:100%}.record-audio[data-v-e53ee4e2] .el-slider{width:100%}.record-audio[data-v-e53ee4e2] .el-slider__bar{background-color:#1dc392;height:8px}.record-audio[data-v-e53ee4e2] .el-slider__runway{height:8px}.record-audio[data-v-e53ee4e2] .el-slider__button{width:26px;height:26px;background-color:#c5c5c5;border:none}", ""]);
// Exports
module.exports = exports;
